import React from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { StaticImage } from "gatsby-plugin-image";
import Box from "@material-ui/core/Box";

const CompanyCard = ({ className }) => (
	<Container className={className} height="100">
		<Grid container spacing={0.5}>
			<Grid item md={2} xs={4}>
				<Box my={3}>
					<StaticImage
						alt="Hong Seh - Pepper Cloud Customer"
						height="35"
						src="../../images/v2/client-logos/hong-seh.png"
					/>
				</Box>
			</Grid>
			<Grid item md={2} xs={4}>
				<Box my={3}>
					<StaticImage
						alt="Singapore Maritime Foundation - Pepper Cloud customer"
						height="100"
						src="../../images/v2/client-logos/singapore-maritime-foundation-pepper-cloud-customer.png"
					/>
				</Box>
			</Grid>
			<Grid item md={2} xs={4}>
				<Box my={3}>
					<StaticImage
						alt="Mustard seed system corporation - Pepper Cloud customer"
						height="100"
						src="../../images/v2/client-logos/mustard-seed-system-corporation-pepper-cloud-customer.png"
					/>
				</Box>
			</Grid>
			<Grid item md={2} xs={4}>
				<Box my={3}>
					<StaticImage
						alt="Cuscapi Singapore Pte. Ltd. - Pepper Cloud customer"
						height="100"
						src="../../images/v2/client-logos/cuscapi-singapore-pte-ltd-pepper-cloud-customer.png"
					/>
				</Box>
			</Grid>
			<Grid item md={2} xs={4}>
				<Box my={3}>
					<StaticImage
						alt="Tech Data - Pepper Cloud customer"
						height="100"
						src="../../images/v2/client-logos/tech-data-pepper-cloud-customer.png"
					/>
				</Box>
			</Grid>
			<Grid item md={2} xs={4}>
				<Box my={3}>
					<StaticImage
						alt="germaxco - Pepper cloud customer"
						height="100"
						src="../../images/v2/client-logos/germaxco-shipping-agencies-pte-ltd-pepper-cloud-client.png"
					/>
				</Box>
			</Grid>
		</Grid>
	</Container>
);

CompanyCard.propTypes = {
	className: PropTypes.string,
};

export default CompanyCard;
